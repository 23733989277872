import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function Page({ data }: {
  data: GatsbyTypes.PageDetailsQuery;
}) {
  const { frontmatter, html } = data.markdownRemark;
  const { slug, title } = frontmatter;

  return (
    <Layout id={`page-${slug}`}>
      <SEO title={title}/>
      <div className="page-standard">
        <h1>{title}</h1>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

type PageNode = {
  html: string;
  frontmatter: {
    title: string;
    date: string;
    slug: string;
  };
};

export const pageQuery = graphql`
  query PageDetails ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;